import React from "react";
import {useEffect} from "react";

import {Button} from "antd";
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";

function Automate(props) {
  console.log("Automate:" + JSON.stringify(props));

  
  useEffect(() => {

    function checkEscape(event) {
      if(event.keyCode === 27) {
        props.setShowAutomation(false);
        //Do whatever when esc is pressed
      }  
    }

    document.addEventListener("keydown", checkEscape, false);

    return () => {
      document.removeEventListener("keydown", checkEscape, false);
    };

  }, [props]);



  var cmd = "curl ";
  var replace = "<insert ID Token JWT here>";

  if (Object.keys(props.inputs).length > 0) {

    cmd = cmd + "-d '" + JSON.stringify(props.inputs) + "'";
  }

  cmd = cmd + " -H 'Authorization: Bearer <insert ID Token JWT here>' -H 'Content-Type: application/json' -X POST '" + props.url + "runSequenceAuthenticated?sequence_id=" + props.sqid + "'";
  
  return (
      <div>
        <div style={{  }}>
          <div className="SQPage">
            <h3>Automate this sequence with the curl command:</h3>
            <p>Take care to replace the part of the command that reads '{replace}' with your JWT token.</p><br/><br/>
            {cmd}

            <br />
            <div style={{marginTop:"30px"}}>
              <Button
                type="link"
                htmlType="submit"
                onClick={e => {
                  props.setShowAutomation(false);
                }}
              >
                Return to Run
              </Button>
            </div>

          </div>
        </div>
      </div>
  ); 
}
export default Automate;
