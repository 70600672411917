
import {
  Button,
  Popover,
  Col,
  Card,
  PageHeader,
  Drawer,
  Row,
  Divider, 
  Menu, 
  Dropdown,
  Alert
} from "antd";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp
} from "@aws-amplify/ui-react";

import { AuthState } from "@aws-amplify/ui-components";
import { fetchCollection, fetchSequenceSummary, checkSequence } from "./Utils";
import {CredentialsContext, addSignInListener, removeSignInListener, signOut } from "./CredentialsContext";
import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { MenuOutlined, LogoutOutlined, UserOutlined, HistoryOutlined, LoginOutlined, PlusOutlined} from '@ant-design/icons';
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import Endpoints from "./Run";

import {basicSetup} from "@codemirror/basic-setup"
import { EditorView } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import {json /*, jsonParseLinter*/} from "@codemirror/lang-json"

//import locale from "react-json-editor-ajrm/locale/en";

function LoadingCard() {

  return (

      <Card title="    " 
            style={{ width: 400, margin: "10px", bordered:true }} 
            loading={true}>

        <Button
            style={{
              float: "right",
              marginRight: "10px"
            }}
            type="primary"
            htmlType="submit"
            diabled
            //onClick={() => selectedTest(value, true, inputs[key])}
          >
            Review
        </Button>
      </Card>
  );
}

function AsyncSequenceCard(props) {

  const [data, setData] = useState({name: "", description: "", owner: ""});

  useEffect(() => {

    fetchSequenceSummary(props.id).then(function(value) {
      //console.log("Sequence:" + JSON.stringify(value.data));

      setData({name: value.data.Name, 
              description: value.data.Description,
              author: value.data.user.name});
    })
    .catch(function(error) {
      console.error(error);
    });

    return () => {
      // Clean up the subscription
    };
  }, [props.id]);

  return (
      <SequenceCard name={data.name} id={props.id} author={data.author} description={data.description}/>
  );
}

function SequenceCard(props) {


  const history = useHistory();

  return (
    
      <Card
        title={props.name}
        bordered={true}
        style={{ width: 400, margin: "10px" }}
      >
        <p>{props.description}</p>
        <br />
        <div
          key={props.id}
          style={{
            color: "gray",
            fontSize: "12px"
          }}
        >
          <p>Author: {props.author}</p>
        </div>
        <br />
        <Button
          style={{
            float: "right",
            marginRight: "10px"
          }}
          type="primary"
          htmlType="submit"
          onClick={e => {
            //e.preventDefault();
            history.push( "/sequence?id=" + props.id);
          }}
        >
          Run
        </Button>
        <Button
          style={{
            float: "right",
            marginRight: "10px"
          }}
          type="primary"
          htmlType="submit"
          onClick={e => {
            //e.preventDefault();
            history.push( "/edit?id=" + props.id);
          }}
        >
          View
        </Button>
      </Card>
  );
}

function CollectionView(props) {

  const [collection, setCollection] = useState(null);

  useEffect(() => {

    //console.log("fetch: " + props.id);

    fetchCollection(props.id).then(function(value) {

      //console.log("fetched: " + JSON.stringify(value.data));
      setCollection(value.data);
    })
    .catch(function(error) {
      console.error(error);
    });

    return () => {
      // Clean up the subscription
    };
  }, [props.id]);

  var elements = [];
  var keyIndex = 0;

  function processCollection(collection) {

    elements.push(<div key={keyIndex++}><h1>{collection.name}</h1></div>);
    elements.push(<div key={keyIndex++}><p>{collection.description}</p></div>);

    var cards = [];

    collection.children.forEach(child => {

      if (child.type === "SQ") {

        cards.push(<Col key={keyIndex++}><AsyncSequenceCard id={child.id}></AsyncSequenceCard></Col>);
      }
      else {

        if (cards.length > 0) {
          elements.push(<Row key={keyIndex++}>{cards}</Row>);
          cards = [];
        }
      
        elements.push(<CollectionView key={keyIndex++} id={child.id}/>);
      }
    });  

    if (cards.length > 0) {
      //console.log("p4 ");
      elements.push(<Row key={keyIndex++}>{cards}</Row>);
      cards = [];
    }
  }

  if (collection === null) {

    var cards = [];

    for (var index = 0; index < 5; index++) 
      cards.push(<Col key={keyIndex++}><LoadingCard key={keyIndex++}></LoadingCard></Col>);

    elements.push(<Row key="load">{cards}</Row>);
  }
  else {

    processCollection(collection);
  }

  return (
          
    <div>
        <Divider />
        {elements}
    </div>
  );
}
/*
function SaveOrClone(props) {
  
  const [isMine, setIsMine] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const isSignedIn = useSignInStatus();

  const history = useHistory();



  if (props.template === undefined) {
    setEnabled(false);
  }
  else {
    setEnabled(true);
  }

  function save() {

    if (props.template !== null) {
      setIsSaving(true);
      updateSequence(key, props.template).then(function(value) {
        history.push( "/sequence?id=" + props.template.ID );
      })
      .catch(function(error) {
        if (error.response !== undefined)
          console.error(error.response);
        else
          console.error(error.message);
      })
      .finally(function() {
        setIsSaving(false);
      });
    }
  }

  if (isMine)
    return (
      <Button onClick={save} disabled={enabled} loading={isSaving}>
        Save
      </Button> 
    );

  return (
    <Button>
      Clone
    </Button> 
  );
}*/

export function useSignInStatus() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const context = useContext(CredentialsContext);

  useEffect(() => {

    const listener = async data => {
      setIsSignedIn(data.payload.data.isSignedIn);
    };
    addSignInListener(listener);
    
    console.log("useSignInStatus, is signedIn: " + context);
    setIsSignedIn(context);

    return () => {
      // Clean up the subscription
      removeSignInListener(listener);
    };
  }, [isSignedIn, context]);

  return isSignedIn;
}

function SQHeader(props) {

  const history = useHistory();
  const isSignedIn = useSignInStatus();
  const context = useContext(CredentialsContext);

  const logout = () => {
    console.log("logout");
    signOut(context, history);
  };  

  var showBack = () => window.history.back();
  if (props.showBack !== undefined ) {
    if (!props.showBack) {
      showBack = false;
    }
  } 

  function goHome() {
    history.push( "/");
  }

  function menuClick(item) {
    console.log("menu key:" + item.key);

    if (item.key === "0") {
      history.push( "/mysequences");
    }
    else if (item.key === "1") {
      history.push( "/newsequence");
    }
    else if (item.key === "2") {
      history.push( "/runs");
    }
    else if (item.key === "3") {
      logout();
    }
  }

  var  buttons = [];

  if (isSignedIn) {

    const menu = (
      <Menu onClick={menuClick}>
        <Menu.Item key="0" icon={<UserOutlined />}>
          My Sequences
        </Menu.Item>
        <Menu.Item key="1" icon={<PlusOutlined />}>
          New Sequence
        </Menu.Item>
        <Menu.Item key="2" icon={<HistoryOutlined />}>
          Runs
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="3" icon={<LogoutOutlined />}>Sign Out</Menu.Item>
      </Menu>
    );
  
    var dropdown = <Dropdown key="dropdown" overlay={menu} trigger={['click']}>
                        <MenuOutlined />
                    </Dropdown>
/*
    var dropdown = <Dropdown key="dropdown" overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <MenuOutlined />
                      </a>
                    </Dropdown>
*/
    buttons = [dropdown];
  }
  else {
    buttons = [<LoginDrawer key="l1" buttonType="danger" buttonText="Sign Up" auth={AuthState.SignUp} />, 
                <LoginDrawer icon={<LoginOutlined />} key="l2" buttonText="Sign In" auth={AuthState.SignIn} />]
  }

  const subtitle = (props.subtitle !== undefined) ? props.subtitle : "";

  return (
    <div >

      <PageHeader
        key="ph0"
        ghost={true}
        onBack={showBack}
        title={<Button style={{height:"45px"}} onClick={goHome} type="link" ><img src="/logo.png" height="40px" alt="The Sequence logo" /></Button>}
        subTitle={subtitle}
        extra={buttons}
      >
      </PageHeader>
    </div>
  );
}

function LoginDrawer(props) {

  //console.log("LoginDrawer:" + props.auth);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleCancel = () => {
    //props.hide();
    setDrawerVisible(false);
    console.log("Cancel " + props.buttonText);
  };

  function openDrawer() {
    setDrawerVisible(true);
    console.log("Open " + props.buttonText);
  }

  return (
    <span>
      <Button icon={props.icon} type={props.buttonType} key="3" onClick={openDrawer}>{props.buttonText}</Button>
      <Drawer destroyOnClose={true} visible={drawerVisible} onClose={handleCancel} width={550} >
        <AmplifyAuthenticator usernameAlias="email" initialAuthState={props.auth} >
          
          <AmplifySignIn headerText="Sign in to Sequence API" slot="sign-in" />

          <AmplifySignUp
            usernameAlias="email"
            headerText="Join Sequence API"
            formFields={[
              {
                type: "name",
                label: "Name",
                placeholder: "e.g. Winston Churchill",
                required: true,
                handleInputChange: (event, cb) => {
                  console.log("custom name field");
                  cb(event);
                }
              },
              {
                type: "email",
                label: "Email Address",
                placeholder: "e.g. winstonchurchill@gmail.com",
                required: true,
                handleInputChange: (event, cb) => {
                  console.log("custom username field");
                  cb(event);
                }
              },
              {
                type: "password",
                label: "Password",
                placeholder: "",
                required: true,
                handleInputChange: (event, cb) => {
                  console.log("custom username field");
                  cb(event);
                }
              }
            ]}
            slot="sign-up"
          ></AmplifySignUp>
        </AmplifyAuthenticator>
      </Drawer>
    </span>
  );
}

function SQButton(props) {

  //console.log(JSON.stringify(props));
  const history = useHistory();
  const isSignedIn = useSignInStatus();

  function handleClick(e) {
    e.preventDefault();

    if (isSignedIn) {
      if (props.href !== undefined) {
        history.push( props.href );
      }
      
      if (props.onClick !== undefined) {
        try {
          props.onClick(e); 
        }
        catch (error) {}
      }
           
    }
    else {
      console.log("not logged in");
    }
  }

  const placement = props.placement !== undefined ? props.placement : "top";

  const content = (
    <div>
      <p>You must sign in to use this feature</p>
    </div>
  );

  if (isSignedIn)
    return (
        <Button {...props} onClick={handleClick}>
          {props.children}
        </Button>
    );
  else
    return (
      <Popover placement={placement} content={content} title="Sign In" trigger="hover">
        <Button {...props} onClick={handleClick} >
          {props.children}
        </Button>
      </Popover>
    );
}

function Editor(props) {


  const [orderedTemplate] = useState({name:props.template.name,
                                      description: props.template.description,
                                      dictionary: props.template.dictionary,
                                      outputs: props.template.outputs,
                                      endpoints: props.template.endpoints}); 

  const [data, setData] = useState({template: orderedTemplate, 
                                    name: orderedTemplate.name, 
                                    description: orderedTemplate.description, 
                                    required: props.required, 
                                    rTemplate: props.rTemplate});
        
  const [newData, setNewData] = useState({text: orderedTemplate, isValid: false, isChecked: true});                                  

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [error, setError] = useState({visible: false, text:""});
  const [checking, setChecking] = useState(false);

  const editor = useRef();

  const notify = useCallback(
    (isValid, newText) => {
      if (props.onInvalidation !== undefined) props.onInvalidation();
      setNewData({text: newText, isValid: isValid, isChecked: false});
    },
    [],
  );

  useEffect(() => {

    const state = EditorState.create({
      doc: JSON.stringify(orderedTemplate, null, 2),
      extensions: [basicSetup, 
                  json(),
                  EditorView.updateListener.of((v) => {
                    if (v.docChanged) {
                      try {

                        var text = v.state.doc;
                        const parsedText = JSON.parse(text);
                        notify(true, parsedText);
                        //setData({...data, template: parsedText, isValid: true});
                      }
                      catch(err) {
                        //console.log("failed: " );
                        notify(false, v.state.doc, v);
                        //setData({...data, isValid: false});
                      }
                      
                    }
                })]
    });
    const view = new EditorView({ state, parent: editor.current });
    return () => {
      view.destroy();
    };
  }, [notify, orderedTemplate]);

  const handleClose = () => {
    setError({visible: false, text: ""});
  };

  function doCheck() {
    setChecking(true);
    setError({visible: false, text: ""});
    checkSequence({template: newData.text})
    .then(function(value) {
      //console.log("Got load response:" + JSON.stringify(value.data));

      setData({
              name: value.data.name, 
              description: value.data.description, 
              required: value.data.required,
              rTemplate: value.data.template,
              template: newData.text
            });

      setNewData({
              text: data.template,
              isValid:false,
              isChecked: true
            });
      //console.log("doCheck:" + JSON.stringify(data));
      //setDrawerVisible(true);

      if (props.onValidation !== undefined) props.onValidation(newData.text);
    })
    .catch(function(err) {
      console.log("ERROR:" + JSON.stringify(err.response.data));
      setError({visible: true, text: JSON.stringify(err.response.data)});
      if (props.onInvalidation !== undefined) props.onInvalidation();
    })
    .finally(function() { 
      setChecking(false);
    });  
  }

  function doRun() {

    if (newData.isChecked) {
      setDrawerVisible(true);
    }
  }

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (

      <div>
        {
          error.visible 
          ? <Alert message={error.text} type="error" closable afterClose={handleClose} />
          : null
        }
        <Row gutter={[16, 16]}>
          <Col>
            <Button style={{marginRight:"10px"}} type="primary" htmlType="submit" onClick={doCheck} loading={checking} disabled={!newData.isValid}>
              Check
            </Button>     
            <Button style={{marginRight:"10px"}} type="primary" htmlType="submit" onClick={doRun} disabled={!newData.isChecked}>
              Run
            </Button>     
            {props.extra}  
          </Col>
        </Row>   
        <div style={{marginTop:"10px", borderStyle:"solid", borderWidth:"1px", borderColor:"lightgrey"}}>

          <div ref={editor}></div>
        </div>   
        <Drawer
          title="Run"
          placement="right"
          width="40%"
          closable={true}
          visible={drawerVisible}
          onClose={onClose}
        >
          <p>{data.name}</p>
          <p>{data.description}</p>
          <Endpoints inputs={data.required} template={data.rTemplate} required={data.required} name={data.name} />
        </Drawer>
      </div>

  ); 
}
/*
function CodeMirrorEditor(props) {

  const [text] = useState(props.text);
  const [listener] = useState(props.listener);


  const notify = useCallback(
    (isValid, newText) => {
      if (listener !== undefined) listener(isValid, newText);
    },
    [],
  );

  //const [listener] = useState(props.listener);
  const editor = useRef();

  useEffect(() => {

    const state = EditorState.create({
      doc: JSON.stringify(text, null, 2),
      extensions: [basicSetup, 
                  json(),
                  EditorView.updateListener.of((v) => {
                    if (v.docChanged) {
                      //console.log("chnage: " + v.state.doc);
                      try {

                        var text = v.state.doc;
                        const parsedText = JSON.parse(text);
                        notify(true, parsedText);
                      }
                      catch(err) {
                        //console.log("failed: " );
                        notify(false, v.state.doc);
                      }
                      
                    }
                })]
    });
    const view = new EditorView({ state, parent: editor.current });
    return () => {
      view.destroy();
    };
  }, [text, notify]);

  return <div ref={editor}></div>

  //return <section ref={editorRef}/>;
}*/


export { SequenceCard, AsyncSequenceCard, LoadingCard, SQHeader, SQButton, CollectionView, Editor };
