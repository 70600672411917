import React from "react";
import {
  Button,
  Spin,
  Tag,
  Menu, 
  Dropdown,
  Row,
  Col,
  Divider
} from "antd";
import { useHistory } from "react-router-dom";
import { SQHeader, SQButton } from "./Components";
import { CopyOutlined, DeleteOutlined, CodeSandboxOutlined, UserOutlined, EditOutlined } from '@ant-design/icons';

import "antd/dist/antd.less";

import "antd/dist/antd.css";
import "./App.css";
import { useState, useEffect, useContext} from "react";

import { fetchSequence, cloneSequence, deleteSequence } from "./Utils";
import { CredentialsContext } from "./CredentialsContext";

import Endpoints from "./Run";

function Sequence(props) {
  //console.log("Test:" + JSON.stringify(props));
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("id");
  const [data, setData] = useState({mine: false, author: "", loaded: false, name: "", description: "", required: [], rTemplate: {endpoints:[], graph:[]}});
  const [cloning, setCloning] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const history = useHistory();
  const context = useContext(CredentialsContext);

  const playgroundURL = "/playground?id=" + key;

  useEffect(() => {

    fetchSequence(key).then(function(value) {
      //console.log("Sequence:" + JSON.stringify(value.data));
      //console.log("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required));
      //console.log("Run:" + JSON.stringify(value.data.rTemplate));

      const orderedTemplate = {};
      orderedTemplate.name = value.data.Template.name;
      orderedTemplate.description = value.data.Template.description;
      orderedTemplate.dictionary = value.data.Template.dictionary;
      orderedTemplate.outputs = value.data.Template.outputs;
      orderedTemplate.endpoints = value.data.Template.endpoints;


      var mine = (value.data.Author === context);

      setData({name: value.data.vTemplate.name, 
              description: value.data.vTemplate.description, 
              required: value.data.vTemplate.dependencies.required,
              rTemplate: value.data.rTemplate,
              template: orderedTemplate,
              loaded: true,
              author: value.data.user.name,
              mine: mine});
    })
    .catch(function(error) {
      console.error(error);
    });

    return () => {
      // Clean up the subscription
    };
  }, [key, context]);

  function deleteSq() {
    setDeleting(true);

    deleteSequence(key).then(function(value) {
      //console.log("/sequence?id=" + value.data.sequence_id);
      history.push( "/mysequences");
      //console.log("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required))
    })
    .catch(function(error) {
      console.error(error.response);
    })
    .finally(function() {       
      setDeleting(false);
    });
  }

  function clone() {
    setCloning(true);

    cloneSequence(key).then(function(value) {
      //console.log("/sequence?id=" + value.data.sequence_id);
      history.push( "/sequence?id=" + value.data.sequence_id );
      //console.log("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required))
    })
    .catch(function(error) {
      console.error(error.response);
    })
    .finally(function() {       
      setCloning(false);
    });
  }

  function openPlayground() {
    history.push( playgroundURL );
  }

  function edit() {
    history.push( "/edit?id=" + key );
  }

  function menuClick(item) {
    console.log("menu key:" + item.key);

    if (item.key === "0") {
      openPlayground();
    }
    else if (item.key === "1") {
      clone();
    }
    else if (item.key === "3") {
      deleteSq();
    }
  }

  const menu = (
    <Menu onClick={menuClick}>
      <Menu.Item key="0" icon={<CodeSandboxOutlined />}>Open in Playground</Menu.Item>
      <Menu.Item key="1" icon={<CopyOutlined />}>Clone</Menu.Item>
      <Menu.Item disabled={deleting} key="3" icon={<DeleteOutlined />}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <div>

      {data.loaded
        ?   
          <div>
            <SQHeader subtitle="Sequence"></SQHeader>

            <div className="SQPage">
              {data.mine 
              ? <Tag><UserOutlined style={{marginRight:"20px"}} />You created this sequence</Tag> 
              : <Tag><UserOutlined style={{marginRight:"20px"}} />{data.author}</Tag>} 
              <h1>{data.name}</h1>
              <p>{data.description}</p>
              <Divider />
              <br />
              <Row>
                {data.mine 
                ? <Col span={2} offset={21}>
                    <Dropdown.Button onClick={edit} overlay={menu}>
                      <EditOutlined />Edit
                    </Dropdown.Button>
                  </Col>
                : <Col span={8} offset={16}>
                    <Button
                      style={{marginRight:"5px"}}
                      onClick={e => {
                        e.preventDefault();
                        history.push( playgroundURL );
                      }}
                      htmlType="submit"
                      href={playgroundURL} 
                      icon={<CodeSandboxOutlined />}
                    >
                      Open in Playground
                    </Button> 
                    <SQButton
                      style={{marginRight:"5px"}}
                      placement="topRight"
                      onClick={clone}
                      loading={cloning}
                      icon={<CopyOutlined />}
                    >
                      Clone
                    </SQButton> 
                  </Col>
                } 
              </Row>
              <Row>
                <Col span={12}>
                  <Endpoints inputs={data.required} template={data.rTemplate} id={key} name={data.name} />
                </Col>
              </Row>
            </div>
          </div>
        : <Spin size="large" />
      }

    </div>
  ); 
}

export default Sequence;
