import React from "react";
import "./App.css";
import AWS from 'aws-sdk/global'

import { CollectionView, SQHeader } from "./Components";
import { CredentialsContext } from "./CredentialsContext";
import {  createUID } from "./Utils";

import "antd/dist/antd.less";
import "antd/dist/antd.css";
import { useState, useEffect} from "react";
import AWSMqttClient from 'aws-mqtt'
import { Auth } from "@aws-amplify/auth";

import Collection from "./Collection";
import Sequence from "./Sequence";
import MySequences from "./MySequences";
import Playground from "./Playground";
import NewSequence from "./NewSequence";
import Runs from "./Runs";

import Automate from "./Automate";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Edit from "./Edit";
import { Amplify, Hub } from "aws-amplify";

const region = process.env.REACT_APP_REGION;
const idPoolID = process.env.REACT_APP_ID_POOL_ID;
const poolID = process.env.REACT_APP_USER_POOL_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;
const endpoint = process.env.REACT_APP_ENDPOINT;

function App() {

  const [iotListener, setIotListener] = useState(undefined);
  const [sub, setSub] = useState(undefined);

  useEffect(() => {
    console.log("CONFIGURING AMPLIFY,region :" + region);

    Amplify.configure({
      Auth: {
        region: region,
        userPoolId:  poolID,
        userPoolWebClientId: clientID,
        mandatorySignIn: true
      }
    });

    AWS.config.region = region;

    console.log("CONFIGURING credentials");

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: idPoolID
    });

    AWS.config.credentials.getPromise()
    .then(function() {
      console.log("INSTANTIATING IOT LISTENER");

      //console.log("Got load response:" + JSON.stringify(value));

      const listener =  new AWSMqttClient({
        region: AWS.config.region,
        credentials: AWS.config.credentials,
        endpoint: endpoint, // NOTE: get this value with `aws iot describe-endpoint`
        expires: 600, // Sign url with expiration of 600 seconds
        clientId: 'mqtt-client-' + createUID(), // clientId to register with MQTT broker. Need to be unique per client
        will: {
            topic: 'WillMsg',
            payload: 'Connection Closed abnormally..!',
            qos: 0,
            retain: false
        } 
      });
  
      listener.on('message', (topic, data) => {
        data = JSON.parse(data);
        console.log("Received msg, topic: " + topic + " data: " + JSON.stringify(data));

        Hub.dispatch(
          data.message.runID , 
          { 
              event: 'runUpdate', 
              data: data.message, 
              message:'' 
          }
        );

        if (data.message.mode === "S") {
          Hub.dispatch(
            "runUpdate" , 
            { 
                event: 'runUpdate', 
                data: data.message, 
                message:'' 
            }
          );
        }

      });

      setIotListener(listener);
    })
    .catch(function(error) {
      console.error("Gerror:" + error);
    });

    return () => {
      // Clean up the subscription

    };
  }, []);

  useEffect(() => {

    console.log("CONFIGURING ID");

    function setCredentials(logins) {
      try {
        //window.localStorage.removeItem("aws.cognito.identity-id." + idPoolID);
  
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: idPoolID, // your identity pool id
          Logins: logins
        });
      }
      catch (err) {
        console.log(err);
      }
    }

    if (iotListener !== undefined) {
      console.log("iotListener OK: " + sub);

      Auth.currentAuthenticatedUser()
      .then(user => {
        console.log("found authenticated user: " + user.username);

        setSub(user.username);
        const logins = {};
        const token = user["signInUserSession"]["idToken"]["jwtToken"];
        logins['cognito-idp.' + region + '.amazonaws.com/' + poolID] = token;
        //setCredentials(logins);
        //AWS.config.credentials.logins = logins;
        console.log("SUBSCRIBING IOT AUTHENTICATED 1: " + sub);

        iotListener.subscribe("/" + user.username);  
        //console.log(JSON.stringify(user));
      })
      .catch(function(error) {
        console.log("auth error:" + error);
        setCredentials({});

        AWS.config.credentials.getPromise()
        .then(function() {
          console.log("SUBSCRIBING IOT  UNAUTHENTICATED ");
          iotListener.subscribe("/" + AWS.config.credentials._identityId);  
        })
        .catch(function(error) {
          console.log("Gerror 3:" + error);
        });
      });

      const hubListener = async data => {
        const { payload } = data;
        console.log("Auth event", JSON.stringify(payload));
      
        if (payload.event === "signIn") {
          console.log("SIGNED IN ");
          setSub(payload.data.username);
          const token = payload["data"]["signInUserSession"]["idToken"]["jwtToken"];
          console.log(token);

          AWS.config.credentials.getPromise()
          .then(function() {

            console.log("SUBSCRIBING IOT AUTHENTICATED 2: " + payload.data.username);
            iotListener.subscribe("/" + payload.data.username);  

            //setTopic(myTopic);
          })
          .catch(function(error) {
            console.log("Gerror2:" + error);
          });


        /*
          try {
            //window.localStorage.removeItem("aws.cognito.identity-id." + idPoolID);
            const token = user["signInUserSession"]["idToken"]["jwtToken"];
            const logins = {};
            logins['cognito-idp.eu-west-1.amazonaws.com/' + poolID] = token;
      
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: idPoolID, // your identity pool id
              Logins: logins
            });
          }
          catch (err) {
            console.log(err);
          }*/
        }
        else if (payload.event === "signOut") {
          setSub(undefined);
        }
      }
      console.log("SUBSCRIBING HUB");
      Hub.listen("auth", hubListener);
    }
    return (hubListener) => {
      // Clean up the subscription
      console.log("UNSUBSCRIBING, sub:" + sub);
      Hub.remove("auth", hubListener);
      if (iotListener !== undefined) iotListener.unsubscribe("/" + sub);  
      AWS.config.credentials.getPromise()
      .then(function() {
        if (iotListener !== undefined) {
          console.log("UNSUBSCRIBING IOT UNAUTHENTICATED ");
          iotListener.unsubscribe("/" + AWS.config.credentials._identityId);  
        }
      })
      .catch(function(error) {
        console.log("Gerror 4:" + error);
      });

    };
  }, [iotListener, sub]);

  return (
    <div style={{
      textAlign: "left",
      background: "#F9F7F7",
      margin: "0px",
      padding: "20px"

    }}>      
      <CredentialsContext.Provider value={sub}>
        <Router basename="/">
          <Switch>
            <Route exact path="/" component={Top} />
            <Route path="/sequence" component={Sequence} />
            <Route path="/playground" component={Playground} />
            <Route path="/collection" component={Collection} />
            <Route path="/mysequences" component={MySequences} />
            <Route path="/newsequence" component={NewSequence} />
            <Route path="/automate" component={Automate} />
            <Route path="/runs" component={Runs} />
            <Route path="/edit" component={Edit} />
          </Switch>
        </Router>  
      </CredentialsContext.Provider>
    </div>
  );
}

function Top () {
  return (
    <div>
      <SQHeader showBack={false}></SQHeader>
      <CollectionView id="fcf4i8" />
    </div>
  );
}


export default App;
