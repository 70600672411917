import axios from "axios";
import AWS from 'aws-sdk/global'
import { Auth } from "@aws-amplify/auth";

export const basicTemplate ={
  name: "My Sequence",
  description: "A sequence that calls some API.",
  dictionary:{
    url: "https://mydefaultserver.com/"
  },
  outputs: [],
  endpoints: [{
      name: "The first API call",
      resource: "someaction",
      url: "https://overridedefaultserver.com/",
      headers: {
        'x-api-key': 'Some Random Key'
      } , 
      parameters: {
      },
      data: {
        json: {

        }
      },
      goals: {
        status: 200,
        output: {
        }
      }
    }
  ] 
}

export const url = "https://api." + process.env.REACT_APP_SUBDOMAIN + ".sequenceapi.com/v1/users/";

export function runFromID(id, inputs, context ) {
  //console.log("MY IDENTITY: " + AWS.config.credentials._identityId);

  if (context !== undefined) 
    return callWithAuthorization("runSequenceAuthenticated", {sequence_id: id}, inputs);
  return call("runSequenceUnauthenticated", {sequence_id: id, requester: AWS.config.credentials._identityId}, inputs);
}

export function runFromTemplate(vTemplate, name, inputs, required, context) {

  const data = {inputs: inputs, template: vTemplate, required: required, name: name};
  console.log("MY template IDENTITY : " + AWS.config.credentials._identityId);

  if (context !== undefined) 
    //return call("runSequenceFromTemplate", {requester: AWS.config.credentials._identityId}, data);
    return callWithAuthorization("runSequenceFromTemplateAuthenticated", {}, data);
  return call("runSequenceFromTemplateUnauthenticated", {requester: AWS.config.credentials._identityId}, data);
}

export function createSequence(template) {

  const data = {template: template};

  return callWithAuthorization("createSequence", {}, data);
}

export function updateSequence(id, template) {

  const data = {template: template};

  return callWithAuthorization("updateSequence", {sequence_id: id}, data);
}

export function deleteSequence(id) {

  return callWithAuthorization("deleteSequence", {sequence_id: id});
}

export function fetchSequence(id) {

  return call("fetchSequence", {sequence_id: id});
}

export function fetchSequenceVersion(id, version) {

  return call("fetchSequence", {sequence_id: id, template_version: version});
}

export function fetchMySequences() {

  return callWithAuthorization("fetchMySequences", {});
}

export function fetchMyRuns(olderThan) {

  if (olderThan !== undefined) return callWithAuthorization("fetchMyRuns", {date: olderThan});

  return callWithAuthorization("fetchMyRuns", {});
}

export function fetchRunHistory(rid) {

  return callWithAuthorization("fetchRunHistory", {run_id: rid});
}

export function fetchSequenceSummary(id) {

  return call("fetchSequenceSummary", {sequence_id: id});
}

export function cloneSequence(id) {

  return callWithAuthorization("cloneSequence", {sequence_id: id});
}

export function fetchCollection(id) {

  return call("fetchCollection", {collection_id: id});
}

export function cloneCollection(id) {

  return callWithAuthorization("cloneCollection", {collection_id: id});
}

export function createCollection(name, description) {

  return callWithAuthorization("createCollection", {name: name, description: description});
}

export function addToSequenceToCollection(collection_id, sequence_id) {

  return callWithAuthorization("addToCollection", {collection_id: collection_id, sequence_id: sequence_id, type: "SQ"});
}

export function deleteFromCollection(collection_id, sequence_id) {

  return callWithAuthorization("deleteFromCollection", {collection_id: collection_id, sequence_id: sequence_id});
}

export function deleteCollection(id) {

  return callWithAuthorization("deleteCollection", {collection_id: id});
}

export function fetchCollections() {

  return callWithAuthorization("fetchCollections", {});
}

export function checkSequence(vTemplate) {

  return call("checkSequence", {}, vTemplate);
}

function convertMapToURLParams(params) {
  if (params === undefined) return "";

  if  (Object.keys(params).length === 0) return "";

  return (
    "?" +
    Object.keys(params)
      .map(key => key + "=" + params[key])
      .join("&")
  );
}

function call(resource, params, data) {
  console.log("call");

  const options = {
    method: "POST",
    mode: "cors",
    headers: {"Content-Type": "application/json"},
    url: url + resource + convertMapToURLParams(params)
  };

  if (data !== null) options.data = data;

  return axios(options);
}

async function callWithAuthorization(resource, params, data) {
  console.log("callWithAuthorization 1");

  return await Auth.currentAuthenticatedUser()
  .then(user => {
    var token = user["signInUserSession"]["idToken"]["jwtToken"];
    //console.log("User: ", JSON.stringify(user));
    //console.log(token);

    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      url: url + resource + convertMapToURLParams(params)
    };

    if (data !== null) options.data = data;
    console.log("callWithAuthorization 2:" + JSON.stringify(options));
  
    return axios(options);
  })
  .catch(function(error) {
    console.error(error);

    console.error("callWithAuthorization invoked when not logged in");
    throw new Error("callWithAuthorization invoked when not logged in");
  });
}

export function createUID() {
  return Math.random()
  .toString(20)
  .substr(2, 6);
};
