import React, {useState, useEffect, useContext} from "react";
import { Button, Spin, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { updateSequence, fetchSequence, cloneSequence } from "./Utils";
import { CodeSandboxOutlined } from '@ant-design/icons';
import { CredentialsContext } from "./CredentialsContext";

import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";

import { SQHeader, Editor } from "./Components";

function Edit(props) {
  console.log("Edit:" + JSON.stringify(props));
  const [data, setData] = useState(null);
  const [newTemplate, setNewTemplate] = useState({template: null, isSaved: true});
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("id");
  const mySub = useContext(CredentialsContext);
  const { Text } = Typography;

  useEffect(() => {

    fetchSequence(key).then(function(value) {

      console.log("fetched: " + JSON.stringify(value.data));

      setData({template: value.data.Template, 
              rTemplate: value.data.rTemplate,
              required: value.data.vTemplate.dependencies.required,
              author: value.data.Author
            });
    })
    .catch(function(error) {
      console.error(error);
    })      
    .finally(function() { 
      setLoading(false);
    });       
    
    return () => {
      // Clean up the subscription
    };
  }, [key]);

  function invalidated() {

    setNewTemplate({template: null, isSaved: false});
  }

  function validated(template) {
    setNewTemplate({template: template, isSaved: false});
  }

  function save() {

    if (newTemplate.template !== null) {
      setIsSaving(true);
      updateSequence(key, newTemplate.template).then(function(value) {
        //invalidated();
        setNewTemplate({template: null, isSaved: true});

        //history.push( "/sequence?id=" + key );
      })
      .catch(function(error) {
        if (error.response !== undefined)
          console.error(error.response);
        else
          console.error(error.message);
      })
      .finally(function() {
        setIsSaving(false);
      });
    }
  }

  function clone() {

    setIsSaving(true);

    cloneSequence(key).then(function(value) {
      //console.log("/sequence?id=" + value.data.sequence_id);
      history.push( "/sequence?id=" + value.data.sequence_id );
      //console.log("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required))
    })
    .catch(function(error) {
      console.error(error.response);
    })
    .finally(function() {       
      setIsSaving(false);
    });
  }

  var extra;
  
  if (!loading) {

    console.log("mySub=" + mySub);
    console.log("author=" + data.author );


    if (mySub === data.author ) { 
      extra = 
      <span>
        <Button onClick={save} disabled={newTemplate.template === null} loading={isSaving}>
            Save
        </Button>  
        {
          (newTemplate.isSaved) ? null :
          <Text type="secondary" style={{ marginLeft: "10px" }}>Unsaved changes</Text> 
        }
      </span>
    }
    else {
      extra = 
        <Button onClick={clone} disabled={newTemplate.template === null} loading={isSaving}>
          Clone
        </Button>  
    }     
  }

  return (
      <div>

        <SQHeader subtitle="Sequence"></SQHeader>
        <div style={{ margin: "50px" }}>
          <div className="SQPage">
            <h1><CodeSandboxOutlined style={{marginRight:"20px"}} />Edit</h1>
            <p>Edit the JSON, check and run the Sequence and then save it if you are happy.</p>
            <br />
            {
              (loading) 
              ? <Spin />
              : <Editor extra={extra} 
                        id={key}
                        onValidation={validated} 
                        onInvalidation={invalidated} 
                        template={data.template}
                        rTemplate={data.rTemplate}
                        required={data.required}>
                </Editor>
            }
            
          </div>
        </div>
      </div>
  ); 
}
export default Edit;
