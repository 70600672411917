import React from "react";
import { SQHeader, CollectionView } from "./Components";

import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";

function Collection(props) {

  console.log("Collection:" + JSON.stringify(props));
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("id");


  return (
    <div>
      <SQHeader subtitle="Collection"></SQHeader>

      <div className="SQPage">
          
          <div>
              <CollectionView id={key} />
          </div>

      </div>
    </div>
  ); 
}

export default Collection;
